import React, { Component } from 'react';
import Webcam from 'react-webcam';
import Header from '../header';
import CustomButtom from '../custom_button';
import Decibel from './decibel';

import './tys.css';

export default class TestYourSetup extends Component {
  state = {
    test_index: 0,
    show_back: false,
    show_video: false,
    audio_success: false,
    audio_fail: false,
    start_count: -1,
    audio_result: false
  };

  close_test_setup = () => {
    this.setState({
      test_index: 0,
      show_back: false,
      show_video: false,
      audio_success: false,
      audio_fail: false,
      start_count: -1,
      audio_result: false
    });
    this.props.close_test_setup();
  };

  start_count_down = () => {
    this.setState({ start_count: 3 }, () => {
      this.count_from_three();
    });
  };

  start_count_down_from_ten = () => {
    this.setState({ start_count: 10 }, () => {
      this.count_from_three();
    });
  };

  count_from_three = () => {
    if (this.state.start_count > 0) {
      setTimeout(() => {
        this.setState({ start_count: this.state.start_count - 1 }, () => {
          this.count_from_three();
        });
      }, 1000);
    }
  };

  go_back = () => {
    this.setState({
      show_video: false,
      show_back: false,
      start_count: -1
      // test_index: (this.state.test_index + 1) % 3
    });
  };

  close_video = () => {
    const videoElem = document.getElementsByTagName('video')[0];

    if (videoElem && videoElem.srcObject) {
      const stream = videoElem.srcObject;

      if (stream.getTracks) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
      videoElem.srcObject = null;
    }

    this.setState(
      {
        show_video: false,
        show_back: false,
        start_count: -1
        // test_index: (this.state.test_index + 1) % 3
      },
      () => {
        this.move_next();
        this.start_count_down_from_ten();
      }
    );
  };

  get_class_name = (index) =>
    this.state.test_index === index ? 'custom-tys-header' : 'custom-tys-header tys-blurred-header';

  open_video_component = () => {
    this.setState(
      {
        show_back: true,
        show_video: true
      },
      () => this.start_count_down()
    );
  };

  changeIndex = (e) =>
    this.setState({ test_index: parseInt(e.currentTarget.dataset.div_index, 10) });

  render_step_one = () => (
      <div className="custom-tys-step-one">
        <div className="custom-tys-step-one-content">
          <div className="custom-tys-one-one">
            <div>1. Yoga Mat</div>
            <img
              src="https://images.myyogateacher.com/teacher_web_app/test/ic_mat@3x.png"
              alt="mat"
            />
          </div>
          <div className="custom-tys-one-two">
            <div>2. Space</div>
            <img
              src="https://images.myyogateacher.com/teacher_web_app/test/ic_setup_camera@3x.png"
              alt="cam"
            />
          </div>
        </div>
        <div className="custom-tys-next">
          <CustomButtom label="Next" onClick={this.move_next} />
        </div>
      </div>
    );

  render_step_two = () => (
      <div className="custom-tys-step-two">
        <div className="custom-tys-step-two-content">
          <div className="custom-tys-two-one">
            <img
              src="https://images.myyogateacher.com/teacher_web_app/test/ic_setup_camera@3x.png"
              alt="cam"
            />
          </div>
          <div className="custom-tys-two-two">
            <div className="custom-tys-two-two-item">
              1. Place your Laptop/Computer on the floor or on a table in front of where you intend
              to practice yoga{' '}
            </div>
            <div className="custom-tys-two-two-item">
              2. Make sure to have enough light in the room{' '}
            </div>
            <div className="custom-tys-two-two-item">
              3. Place your mat about 10 to 12 feet away from your Laptop/Computer camera{' '}
            </div>
          </div>
        </div>
        <div className="custom-tys-next">
          <CustomButtom label="Test your camera setup" onClick={this.open_video_component} />
        </div>
      </div>
    );

  move_next = () => {
    let {test_index} = this.state;
    test_index = (test_index + 1) % 3;
    this.setState({ test_index });
  };

  on_audio_success = () => {
    this.setState({ start_count: -1, audio_success: true, audio_fail: false });
  };

  on_audio_fail = () => {
    this.setState({ start_count: -1, audio_success: false, audio_fail: true });
  };

  render_step_three = () => {
    if (this.state.audio_success) {
      return this.render_audio_success();
    }
    if (this.state.audio_fail) {
      return this.render_audio_fail();
    }
    return (
      <div className="custom-tys-step-three">
        <div className="custom-tys-step-three-container">
          <div className="custom-tys-step-three-one">
            Stand on your yoga mat and say <br /> 'Hey myYogaTeacher App, can you hear me well?'
          </div>
          {this.state.start_count === 0 ? (
            <Decibel on_audio_fail={this.on_audio_fail} on_audio_success={this.on_audio_success} />
          ) : (
            <div className="custom-tys-step-three-two">
              Audio starts in {this.state.start_count}
            </div>
          )}
        </div>
      </div>
    );
  };

  render_audio_success = () => (
      <div className="custom-tys-step-three">
        <div className="tys-audio-success">
          <img
            src="https://images.myyogateacher.com/teacher_web_app/test/graphic_thumbs_up@2x.png"
            alt="thumsup"
          />
          <div className="tys-audio-success-message">
            <p>
              We could hear you well!
              <br />
              Setup is complete :-)
            </p>
            <div className="tys-audio-success-tip">
              <img
                src="https://images.myyogateacher.com/teacher_web_app/test/protip@2x.png"
                alt="tip"
              />
              <p>
                Using a bluetooth headset or connecting to a bigger TV screen is recommended for
                better experience
              </p>
            </div>
          </div>
        </div>
        <div className="custom-tys-next">
          <CustomButtom label="All Set" onClick={this.close_test_setup} />
        </div>
      </div>
    );

  try_again_audio = () => {
    this.setState({ start_count: 0, audio_success: false, audio_fail: false });
  };

  render_audio_fail = () => (
      <div className="custom-tys-step-three">
        <div className="tys-audio-success">
          <img
            src="https://images.myyogateacher.com/teacher_web_app/test/headset@2x.png"
            alt="headset"
          />
          <div className="tys-audio-fail-message">
            <div className="tys-fail-message">
              <img
                src="https://images.myyogateacher.com/teacher_web_app/test/ic_error@2x.png"
                alt="excl"
              />{' '}
              We could not hear you well
            </div>
            <div className="tys-fail-plain-message">
              <p>We recommended you pair a bluetooth to your Laptop/Computer and retest</p>
              <p className="tys-fail-or">----or----</p>
              <p>
                If you already have a bluetooth headset connected, you want to pair again and test
              </p>
            </div>
          </div>
        </div>
        <div className="custom-tys-next">
          <button className="tys-fail-button" onClick={this.try_again_audio}>
            Try again
          </button>
        </div>
      </div>
    );

  render_steps = () => {
    if (this.state.test_index === 0) {
      return this.render_step_one();
    } if (this.state.test_index === 1) {
      return this.render_step_two();
    } if (this.state.test_index === 2) {
      return this.render_step_three();
    }
  };

  render_videoscreen = () => {
    if (this.state.start_count === 0) {
      return (
        <div className="custom-tys-full-screen">
          <Webcam
            audio={false}
            videoConstraints={{
              facingMode: 'environment'
            }}
          />
          <div className="custom-tys-video-title">
            Adjust and tilt the camera to align the mat between the two lines
          </div>
          <div className="tys-video-above-line" />
          <div className="tys-video-below-line" />
          <div className="custom-tys-close-video">
            <CustomButtom label="Next" onClick={this.close_video} />
          </div>
        </div>
      );
    }
    return (
      <div className="custom-tys-full-screen">
        <div className="custom-tys-video-container">
          <div className="custom-tys-video-title">
            Adjust and tilt the camera to align the mat between the two lines
          </div>
          <div className="tys-start-message">Starting camera in {this.state.start_count}</div>
          <div className="tys-video-above-line" />
          <div className="tys-video-below-line" />
        </div>
      </div>
    );
  };

  render() {
    if (this.props.test_setup_open) {
      return (
        <div className="custom-tys">
          <Header />
          <div className="custom-tys-container">
            <div className="custom-tys-box">
              <div className="custom-tys-breadcrumb">
                <span className="custom-tys-go-home" onClick={this.close_test_setup}>
                  Home
                </span>{' '}
                &gt; {'  '}Test your setup
              </div>
              {this.state.show_back ? (
                <div className="custom-tys-back" onClick={this.go_back}>
                  <img
                    src="https://images.myyogateacher.com/landing_page/Arrow@2x.png"
                    alt="back"
                  />
                  Back
                </div>
              ) : null}
              {this.state.show_video ? (
                <div className="custom-tys-video-parent flex items-center justify-center">
                  {this.render_videoscreen()}
                </div>
              ) : (
                <div className="custom-tys-steps">
                  <div className="custom-tys-headers">
                    <div className={this.get_class_name(0)}>
                      <div className="custom-tys-header-title">Step 1</div>
                      <div className="custom-tys-header-content">What you will need</div>
                    </div>
                    <div className={this.get_class_name(1)}>
                      <div className="custom-tys-header-title">Step 2</div>
                      <div className="custom-tys-header-content">Setup camera</div>
                    </div>
                    <div className={this.get_class_name(2)}>
                      <div className="custom-tys-header-title">Step 3</div>
                      <div className="custom-tys-header-content">Setup audio</div>
                    </div>
                  </div>
                  <div className="custom-tys-media">{this.render_steps()}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}
